<template>
  <v-container fill-height fluid>
    <v-card-text align="center"><h1>Austellungen und Projekte</h1></v-card-text>

    <v-row
      class="ml-6"
      no-gutters
      align="center"
      justify="center"
      v-for="entry in entries"
      :key="entry.id"
      :class="{'mb-4': $vuetify.breakpoint.smAndDown}"
    >
      <v-col lg="2" class="hidden-md-and-down">
        <v-card flat align="center" color="transparent">
          <div class="timeline">
            <div class="circle"></div>
          </div>
        </v-card>
      </v-col>

      <v-col lg="4">
        <v-card outlined align="center">
          <v-list-item>
            <v-list-item-avatar>
              <v-img
                class="flag"
                :src="getFlag(entry.country)"
                :alt="entry.country"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="headline mb-4 text-wrap" :id="`${entry.id}`">{{ entry.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ entry.dateRange }}</v-list-item-subtitle>
              <v-list-item-subtitle class="text-wrap">{{ entry.where }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text align="left">
            {{ entry.content }}
          </v-card-text>

          <!-- Navigation buttons -->
          <v-btn class="ma-4" @click="scrollTo(entry.nextId)"
            v-show="entry.nextId" alt="Weiter"
          >
            <v-icon>mdi-chevron-triple-down</v-icon>
          </v-btn>
          <v-btn class="ma-4" @click="scrollTo(entry.prevId)"
            v-show="entry.prevId" alt="Zurück"
          >
            <v-icon>mdi-chevron-triple-up</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'Timeline',
  data () {
    return {
      raw_entries: [
        {
          title: 'Konscht Eck',
          dateRange: '10.Sep - 17.Sep.2022',
          content: 'Eine Gruppen Ausstellung internationaler Künstler. Die Künstler haben über mehrere Monaten im Im Kunsthaus gelebt und Kunstprojekte entwickelt. Mein Werk: Ich habe ein Portrait und Affendarstellungen in Acryl auf Leinwand präsentiert. Ein anderes Werk, zeigt eine Fotographie (Zusammenarbeit zwischen: Konzept von Lima Vafa, Performance von Katrin Elsen).',
          where: 'Luxemburg',
          country: 'lu',
        },
        {
          title: 'Koloquio, Auction',
          dateRange: '12.Dez 2021',
          content: 'Eine Gruppenausstellung verschiedener Künstler aus Luxemburg in Zusammenarbeit mit Les amis des Jeunes en Détresse Asbl, Luxemburg. Ich habe persönliche Werke ausgestellt, welche im Centre socio-éducatif (Dreiborn) entstanden sind. Die meisten der Werke waren jedoch die der Schüler. Ausgestellt wurden Malereien und Druckdarstellungen.',
          where: 'Luxemburg',
          country: 'lu',
        },
        {
          title: 'Salon du CAL',
          dateRange: '01.Nov - 22.Nov 2020',
          content: 'Eine Gruppen- Ausstellung verschiedener, internationaler Künstler. Mein Werk: Emotionen und Wasser, Acrylfarbe und natürliche Pigmente auf Leinwand, 145 x 145, Gipsskulptur, Wandschrift, Aquarellgedicht.',
          where: 'Luxemburg',
          country: 'lu',
        },
        {
          title: 'Art and Music',
          dateRange: '07.Sep - 17.Okt 2019',
          content: 'Ausstellung Art and Music, DR Hans - Werner Tilden Stiftung und Klinik Hohe Mark, Prof Andreas Orosz und Studenten',
          where: 'Oberursel',
          country: 'de',
        },
        {
          title: 'Film Projekt Haartari',
          dateRange: '28.Jul 2018',
          content: 'Die fünf Elemente« eine Zusammenarbeit mit Nico Polensky, Max Vaupel & verschiedenen Stylisten, Make-up artist’s und verschiedenen performance Künstlern. Meine künstlerische arbeit war insbesondere die bewegte Darstellung',
          where: null,
          country: 'de',
        },
        {
          title: 'Eine Retrospektive Des Künstlers',
          dateRange: '28.Apr – 29.Apr 2018',
          content: 'Gezeigt werden alte und neue Malereien, Zeichnungen und fotografien. Eine Private Ausstellung in einem Atelier',
          where: 'Berlin',
          country: 'de',
        },
        {
          title: 'Salon du CAL',
          dateRange: '09.Nov - 13.Nov 2016',
          content: 'In der ausstellung »Salon du CAL«, stellen verschiedene internationale Künstler und Galerien aus. Eine Zusammenarbeite mit aija Pole. fotografien »Umwandlung« und »formen«, art WEEK',
          where: 'Luxemburg',
          country: 'lu',
        },
        {
          title: 'Faces 2',
          dateRange: '25.Apr - 27.Apr 2016',
          content: 'In Zusammenarbeit mit »Cock arts«, eine einzel- ausstellung in einem privaten Haus und ein Verkauf des Bildes »Hommage a frida Carlo«',
          where: 'Düsseldorf',
          country: 'de',
        },
        {
          title: 'Salon du CAL',
          dateRange: '14.Nov - 01.Dez 2014',
          content: '“Salon CAL 2014”, cercle artistique de Luxemourg. Präsentiert wird einen Film und eine Fotografie, eine Zusammenarbeit mit Michèle Tonteling. Ausstellung 43 internationaler Künstler, Luxemburg. Presiträgin vom “Prix Pierre Werner”',
          where: 'Luxemburg',
          country: 'lu',
        },
        {
          title: 'Bis in die Poren',
          dateRange: '11.Nov - 11.Dez 2012',
          content: 'Eine Klassenaustellung von Andreas Orosz, 24 Studenten, Absolventen und ihr Professor präsentieren 84 malerische Werke im emsdettener Kunstverein in der Galerie Münsterland',
          where: 'Galerie Münsterland',
          country: 'de',
        },
        {
          title: 'Salon du CAL',
          dateRange: '9.Nov - 2.Dez 2012',
          content: '”Salon 2012”, cercle artistique de Luxemourg, Le CAL in den carréRotondes Ausstellung 38 internationaler Künstler',
          where: 'Luxemburg',
          country: 'lu',
        },
        {
          title: 'Bitte Wenden',
          dateRange: '15.Sep - 30.Sep 2012',
          content: '“Bitte Wenden”, Diplom und Bachelor Ausstellung, des Fachbereichs Bildende Kunst der Alanus Hochschule, Walzwerk Pulheim',
          where: 'Walzwerk Pulheim',
          country: 'de',
        },
        {
          title: '“Faces” Artwork meets Afterwork',
          dateRange: '05.Mar - 06.Mar 2012',
          content: '"Wenn ich nur dieses Gesicht kämmen könnte, dachte ich, aber das geht nicht" - (Charles Bukowski). Als fester Teil des Körpers gehört unser Gesicht ‐ wie auch die Kunst ‐ zur materiellen Welt. Es erscheint uns zunächst in seiner rein physischen Form. Doch was steckt dahinter? Eine Gruppenausstellung 6 internationaler Künstler: Maximilian Westphal Araya Peralta, Dominic Brown, Kornelius Wehrhan‐Mees, Dennis Gonsh, Katrin Elsen',
          where: 'Rudas Studio Düsseldorf',
          country: 'de',
        },
        {
          title: 'Die Sprachverwirrung',
          dateRange: '2012',
          content: 'Turmbau zu Babel-Die Sprachverwirrung, Künstlerbücher, Gruppenausstellung 10 internationaler Künstler',
          where: 'Kunst- und Museums- Bibliothek der Stadt Köln',
          country: 'de',
        },
        {
          title: 'Von dort nach hier',
          dateRange: '22.Apr - 13.Mar 2012',
          content: '”Von dort nach hier” Eine Gruppenausstellung von Ji Hyoun Lee, Benedikt Mast, Maria Ostriz, Elisabeth Reichegger, Katrin Elsen Verein zur Förderung von Kunst und Kultur in Badhonnef',
          where: 'Badhonnef',
          country: 'de',
        },
        {
          title: 'Jack in the Box Köln',
          dateRange: '2012',
          content: 'Einzelausstellung, zusammen mit Sepideh Madani',
          where: 'Jack in the Box, Köln',
          country: 'de',
        },
        {
          title: 'Salon du CAL',
          dateRange: '11.Nov - 04.Dez 2011',
          content: 'Le CAL in den carré Rotondes Ausstellung 40 internationaler Künstler, darunter 10 junge Künstler',
          where: 'Carré Rotondes',
          country: 'lu',
        },
        {
          title: 'La Ghetto',
          dateRange: '11.Jun 2011',
          content: 'Exposition im Haus eines spanischen Künstlers, von Studenten der UB Barcelona und anderen Künstler des Fachs Bildende Kunst',
          where: 'UB Barcelona',
          country: 'es',
        },
        {
          title: 'Rückblick- Einblick- Ausblick',
          dateRange: '01.Nov - 31.Jan 2010',
          content: 'Werkschau von Studenten und Absolventen des Fachs Malerei der Alanus Hochschule in der GLS Bank Bochum',
          where: 'GLS Bank Bochum',
          country: 'de',
        },
        {
          title: 'Art Fair',
          dateRange: '28.Nov - 30.Nov 2010',
          content: 'Art Fair Bloom Award',
          where: 'Köln',
          country: 'de',
        },
        {
          title: 'Below Skin',
          dateRange: '20.Apr - 22.Apr 2010',
          content: 'Cock Arts, eine Einzelausstellung, zusammen mit Sepideh Madani Köln',
          where: 'Jack in the Box, Köln',
          country: 'de',
        },
        {
          title: 'Von Dort Nach Hier III',
          dateRange: '22.Apr 2010',
          content: 'Von Dort Nach Hier III im Kunstraum Bad Honnef',
          where: 'Kunstraum Bad Honnef',
          country: 'de',
        }
      ]
    }
  },

  methods: {
    scrollTo: function(id) {
      /**
       * Helper method to scroll to a certain area on the page
       **/
      var anchor = '#' + CSS.escape(id)
      this.$vuetify.goTo(anchor, { 'offset': 20 })
    },

    getFlag: function(countryString) {
      /**
       * Get's a Country flag based on the country stem i.e 'be', 'de'
       **/
      var filename = countryString + ".png"
      return require(`../assets/images/flags/${filename}`)
    }


  },

  computed: {
    entries: function () {
      /**
       *
       * Adds ID's to the timeline entries
       *
       **/
      var prevId = null  // Initial prev ID
      var nextId = null  // Initial next ID

      this.raw_entries.forEach(function(i, idx, array) {
        if ( idx == 0 ) {
          /* First element get a random id and set the id for next item */
          nextId = uuidv4()
          prevId = uuidv4()
          array[idx].id = prevId
          array[idx].nextId = nextId
          array[idx].prevId = null
        } else {
          /* Reuse old iteration nextId */
          array[idx].prevId = prevId
          array[idx].id = nextId
          prevId = nextId

          /* Generate a new nextId */
          nextId = uuidv4()
          array[idx].nextId = nextId
        }

        /* Special Case: Last item should not have a nextId */
        if (idx == array.length -1) {
          array[idx].nextId = null
        }
      })

      console.log(this.raw_entries)
      return this.raw_entries
    }
  }
}
</script>

<style>
.disabled-link{
  cursor: default;
  pointer-events: none;
  text-decoration: none;
  color: grey;
}

.timeline {
  position: relative;
  width: 5px;
  height: 360px;
  background-color: var(--v-secondary-base);
  /* Ugly hack to get lines of timeline to match up */
  margin: -1px;
}

.circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: var(--v-primary-base);
  border: solid 3px var(--v-secondary-base);
  border-radius: 50%;
  position: absolute;
  left: -12px;
  top: calc(50% - 15px);
}

.flag {
  opacity: 0.65;
}
</style>
