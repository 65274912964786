<template>
  <timeline />
</template>

<script>
import Timeline from '@/components/Timeline.vue'

export default {
  name: 'Vitae',
  components: {
    Timeline
  }
}
</script>
